.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexAllCenter {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flexAlignCenter {
    display: flex;
    align-items: center;
}

.flexOnly {
    display: flex;
}

.isShow {
    display: block;
}

.isHide, .ant-table-wrapper .ant-table-column-sorter{
    display: none !important;
}

.flexBetween {
    display: flex;
    justify-content: space-between;
}

.ant-spin-nested-loading,
.ant-spin-container {
    height: 100%;
}

.ant-spin-nested-loading>div>.ant-spin {
    top: 30%;
}

.ant-spin-nested-loading>div>.ant-spin-lg .ant-spin-text {
    color: #A1B4DB;
    font-size: 0.25rem;
}

.ant-badge.ant-badge-status .ant-badge-status-processing {
    color: #00FB64;
    background-color: #00FB64;
    width: 16px;
    height: 16px;
    top: -7px;
}

.ant-badge.ant-badge-status .ant-badge-status-text {
    color: #00FB64;
    font-size: 32px;
}

.ant-badge.ant-badge-status {
    margin-top: -1rem;
}

.ant-carousel .slick-dots li,
.ant-carousel .slick-dots li button {
    width: 0.12rem;
    height: 0.12rem;
    border-radius: 50%;
}

.ant-carousel .slick-dots li {
    margin-inline: 0.07rem;
}

.ant-carousel .slick-dots li.slick-active {
    width: 0.12rem;
}

.ant-carousel .slick-dots li.slick-active button {
    background: #D8E7FF;
}

.ant-carousel .slick-dots li button {
    background: #4891E3;
}

.ant-carousel .slick-dots-bottom {
    bottom: -0.1rem;
}

/* .dhl {
    background: #A4A3F5;
}

.fedex {
    background: #5CBFD6;
}

.dpd {
    background: #7DCE8B;
}

.error {
    background: #E37A63;
} */

.commonBtn.ant-btn-primary:hover,
.commonBtn.ant-btn-primary:focus {
    background: #164187 !important;
}

.commonBtn.ant-btn.ant-btn-lg {
    height: 0.8rem;
    border-radius: 0.2rem;
    padding: 0 0.3rem;
}

.commonBtn.ant-btn-primary {
    background-color: #164187;
    box-shadow: none;
}

.commonBtn.ant-btn>span {
    color: #C9D8F8;
    font-size: 0.32rem;
    font-weight: 700;
}

.picCell {
    color: #D8E7FF;
    font-size: 0.24rem;
    font-weight: 400;
    text-align: right;
    margin-right: 0.29rem;
    margin-bottom: 0.21rem;
}

.picCell .bold {
    font-weight: 700;
}

.labelBase {
    padding: 0 0.54rem;
}

.antCommonTable.ant-table-wrapper .ant-table-thead>tr>th,
.antCommonTable.ant-table-wrapper .ant-table-thead>tr>td,
.antCommonTable.ant-table-wrapper .ant-table-tbody>tr>th,
.antCommonTable.ant-table-wrapper .ant-table-tbody>tr>td,
.antCommonTable.ant-table-wrapper .ant-table,
.antCommonTable.ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder,
.antCommonTable .ant-table-tbody>tr:hover:not(.ant-table-expanded-row)>td,
.antCommonTable.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
.antCommonTable.ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    background: none;
    border: none;
    color: #D8E7FF;
    font-size: 0.32rem;
}

.antCommonTable.ant-table-wrapper .ant-table-thead>tr>th,
.antCommonTable.ant-table-wrapper .ant-table-thead>tr>td {
    font-size: 0.3rem;
    font-weight: 400;
}

.antCommonTable.ant-table-wrapper .ant-table-cell-scrollbar:not([rowspan]) {
    box-shadow: none;
}
.antCommonTable.ant-table-wrapper .ant-table-body::-webkit-scrollbar {
    height: 5px;
    width: 8px;
}
.antCommonTable.ant-table-wrapper .ant-table-body::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 22px;
    opacity: 0.5;
    background: #2457B0;
}
.antCommonTable.ant-table-wrapper .ant-table-body {
    padding-right: 20px;
}

.antCommonTable {
    background: url('./static/img/table_bg.png') no-repeat;
    background-size: 100% 100%;
    padding: 0 0.3rem 0.34rem 0.6rem;
    border-radius: 0.2rem;
}

.antCommonTable .ant-table-cell.bold {
    font-weight: 700;
}
.antCommonTable .ant-table-body .ant-table-row-hover,
.antCommonTable .ant-table-body .ant-table-row-hover>td,
.antCommonTable.ant-table-wrapper .ant-table-tbody>tr>td {
    background: #19499D !important;
    /* Inner Shadow */
    /* box-shadow: 4px -17px 78px 0px #336BCD inset; */
}

.antCommonTable.ant-table-wrapper .ant-table-tbody>tr>td:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.antCommonTable.ant-table-wrapper .ant-table-tbody>tr>td:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
}
.antCommonTable.ant-table-wrapper .ant-table-body table {
    border-spacing: 0 12px;
    margin-top: -24px;
}
.antCommonTable .ant-empty-normal {
    height: 4.6rem;
    line-height: 4.6rem;
    font-size: 0.32rem;
    text-transform: capitalize;
}
.antCommonTable .ant-empty-normal .ant-empty-image {
    display: none;
}
.antCommonTable .ant-empty-normal .ant-empty-description {
    color: #D8E7FF;
}
.ant-tooltip {
    max-width: 5rem;
}
.ant-tooltip .ant-tooltip-inner {
    color: #021F5E;
    font-size: 0.3rem;
    padding: 0.15rem 0.2rem;
    border-radius: 0.15rem;
    background: #8CCCFF;
    line-height: 1;
}
.ant-tooltip .ant-tooltip-arrow::before {
    background: #8CCCFF;
}
.commonClose {
    font-size: 0.28rem;
    color: #C9D8F8;
    float: right;
    margin: 0.18rem 0.18rem -0.5rem 0;
    cursor: pointer;
    z-index: 1000;
    position: relative;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
    background: none;
}
.checkLine {
    width: 0.34rem;
}
